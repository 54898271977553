import React from "react";
import "./headerDescription.css";
import switchEasy from "../../assets/images/switch-easy.png";
import switchPower from "../../assets/images/switch-power.png";

function HeaderDescription() {
  return (
    <article id="headerDescription" className="position-relative mt-5">
      <section className="d-flex align-items-center justify-content-around">
        <img
          src={switchPower}
          id="iconePower"
          className="iconeDescription position-absolute"
          alt="icone power de 'Chez SWITCH'"
        />
        <h3 className="m-0 fs-2 fw-bold px-5 text-center">
          Découvrez nos offres Électricité
        </h3>
        <img
          src={switchEasy}
          id="iconeEasy"
          className="iconeDescription position-absolute"
          alt="icone easy de 'Chez SWITCH'"
        />
      </section>
      <section
        id="containerResume"
        className="row d-flex flex-column w-100 justify-content-around align-items-center mt-5 mx-0 px-5"
      >
        <p className="text-center col-md-10 fsDescription">
          Si vous êtes à la recherche d'un fournisseur d'énergie pour votre
          domicile actuel ou pour votre futur logement lors d'un déménagement,
          Switch Energy est à vos côtés. En tant que fournisseur d'électricité,
          nous proposons des offres facilement compréhensibles et adaptées à vos
          besoins pour vous accompagner au quotidien.
        </p>
        <p className="text-center fs-2 col-md-10 fw-semibold">
            Switch Energy, l'électricité verte simplifiée.
        </p>
      </section>
    </article>
  );
}

export default HeaderDescription;
