import React from 'react'
import './mainDescription.css'
import IllustrationBarrage from "../../assets/images/illustration-barrage.jpg";
import IllustrationMain from "../../assets/images/illustration-main.jpg";
import IllustrationEolienne from "../../assets/images/illustration-eolienne.png";
import ArticleDescription from '../ArticleDescription/ArticleDescription';

function MainDescription() {
  return (
    <article className='d-flex containerArticle flex-column justify-content-center px-5 px-md-0'>
        <ArticleDescription
        title="Simple, transparent et sans surprise."
        paragraph="Nous comprenons que pour nos clients, l'énergie peut être un sujet compliqué et source de confusion. C'est pourquoi chez Switch Energy, nous nous engageons à simplifier les choses autant que possible. Nous souhaitons vous offrir une expérience claire et transparente, en vous aidant à comprendre exactement ce que vous payez et pourquoi, sans avoir à vous inquiéter des coûts cachés ou des surprises désagréables. Nous sommes là pour vous accompagner et répondre à toutes vos questions de manière honnête et transparente, afin que vous puissiez avoir l'esprit tranquille."
        image={IllustrationBarrage}
        revers="none"
      />
      <ArticleDescription
        title="Des offres vertes"
        paragraph="Opter pour l'énergie verte avec Switch Energy vous permet de participer à un processus éco-responsable axé sur le développement durable, la transition énergétique et la production renouvelable européenne. Les certificats authentifiant la provenance de votre énergie seront disponibles sur votre compte client."
        image={IllustrationMain}
        revers="yes"
      />
      <ArticleDescription
        title="Souscription rapide et facile"
        paragraph="Nous avons conçu une expérience de souscription en ligne simple et rapide pour nos clients. Nous savons que le temps est précieux, c'est pourquoi nous avons simplifié chaque étape du processus d'abonnement. Vous pouvez souscrire en quelques minutes seulement, sans avoir besoin de remplir de longs formulaires ou de fournir des documents compliqués. Nous sommes là pour vous guider à chaque étape du processus et répondre à toutes vos questions. Avec Switch Energy, l'abonnement à l'électricité est simple et rapide."
        image={IllustrationEolienne}
        revers="none"
      />

    </article>
  )
}

export default MainDescription